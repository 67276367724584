<script>

    let browserDetails = {
        'browseronline': navigator.onLine,
        'javaenabled': navigator.javaEnabled(),
        'cookieenabled': navigator.cookieEnabled,
        'browsername': navigator.appName,
        'browsercodename': navigator.appCodeName,
        'browserengine': navigator.product,
        'browserversion': navigator.appVersion,
        'browseragent': navigator.userAgent,
        'platformname': navigator.platform,
        'platformlanguage': navigator.language,
        'screenwidth': screen.width,
        'screenheight': screen.height,
        'pixeldepth': screen.pixelDepth,
        'useridentified': true,
    }

</script>

<style>
    .logBox {
		position: fixed; 
		bottom: 0;
		left: 0;
		padding-left: 2%;
		padding-bottom: 20px;
		text-align: left;
		color: white;
        font-family: 'Major Mono Display';
	}
</style>

<div class='logBox'>
    {#each Object.keys(browserDetails) as key}
        <p style='font-size: 0.7rem; margin-bottom: -0.7rem'>{key}: {browserDetails[key]}</p>
    {/each}
</div>
<script>
	let leftBase = 0
	let topBase = 0
	let position = 0
	let baseline = 0
	
	let zIndex = 0

	function randomNumber(max){
        return Math.floor(Math.random()*max)
    }

	setInterval(function(){
		leftBase= randomNumber(300)
	}, 5000)

	setInterval(function(){
		topBase = randomNumber(10)
	}, 200)

	setInterval(function(){
		baseline = randomNumber(50)
	}, 50)

	setInterval(function(){
		position = randomNumber(10) + baseline + leftBase
	}, 5);
		
	
</script>

<style>
	.draggable {
		position: relative;
	}
	.imageContainer { 
		width: 100%;
		overflow: hidden;
		position: relative;
	}
</style>

<div class='imageContainer'>
	<section style="left: {position}px; z-index: {zIndex}; margin-top:{topBase}px;" class="draggable">
		<slot></slot>
	</section>
</div>

<script>
	import {
		Content,
		Grid, 
		Column, 
		Row,
	} from 'carbon-components-svelte'

	import {
		TextPage,
		ImagePage,
		GlitchComponent
	} from './components'

	import { fade } from 'svelte/transition';

	let clickCount = 0
	let clickPosition = ''
	let trackPlaying = false
	let vocalSample = ''

	let pageOptions = [
		{ page: 'WATER | TEXT', icon: 'landing.svg', component: TextPage },
		{ page: 'WATER | IMAGE', icon: 'landing.svg', component: ImagePage },
	]
	let selected = pageOptions[0]
	let landed = false

	function landedGo() {
		landed=true
		var audio = new Audio("audio/bacground.mp3" )
		audio.volume = 0.3
		audio.play()
		audio.loop = true
		trackPlaying = true
	}

	function pageChange(page) {
		selected = pageOptions[page]
	}

	window.onclick = function(event) {
		let audioOptions = ['audio/consume.mp3', 'audio/easier.mp3']
		let selectedAudio = audioOptions[Math.round(Math.random())]
		let aud = new Audio(selectedAudio)
		aud.volume = (Math.random() * 6)/10
		aud.play()
		clickCount++
		clickPosition = `{x: ${event.pageX}, y: ${event.pageY}}`
		vocalSample = selectedAudio
	}

	
</script>

<style>
	:global(body) {
		padding: 0;
		background-color: rgb(0, 0, 56);
	}

	.bgWrap {
		position: fixed; 
		background-image: url("/images/water_texture.png");
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		filter: opacity(0.1);
		z-index: -1;
	}

	.lander {
		width: 100%;
		height: 70vh;
		margin: auto;
		text-align: center;
	}

	.wrapper {
		padding: 5%;
	}

	.menuBox{
		position: fixed;
		bottom: 0;
		right: 0;
		padding-right: 2%;
		padding-bottom: 20px;
		width: 200px;
		text-align: right;
		color: white;
	}

	h1 {
		color:rgba(255, 255, 255, 0); 
		cursor:crosshair; 
		font-family: 'IM Fell Great Primer SC';
		font-size: 5rem;
		-webkit-text-stroke: 1px white;
		font-style: italic;
	}

	p { 
		font-family: 'Major Mono Display';
	}

	.fixedTitle {
        position: fixed;
        right: 5%;
        top: 15%;
    }

    .transparentTitle {
        font-size: 4rem;
        color: rgba(0, 0, 0, 0);
        font-family: 'IM Fell Great Primer SC';
        font-style: italic;
        -webkit-text-stroke: 1px white;
    }

	.clickCounterBox {
		position: fixed; 
		top: 0;
		left: 0;
		padding-left: 2%;
		padding-top: 2%;
		width: 200px;
		font-family: 'Major Mono Display';
		font-size: 0.7rem;
		color: white;
	}
	img {
		height: 400px;
		cursor:crosshair; 

	}
</style>

<svelte:head>
    <title>-</title>
    <link rel='icon' type='image/png' href='landing.svg'>
</svelte:head>

<div class='wrapper'>
	<div class='bgWrap' />
	<Content>
		<Grid>
			{#if landed == true}
				<div transition:fade>
					<svelte:component 
						this={selected.component}
						pageLabel={selected.page}
						pageIcon={selected.icon}
					/>
				</div>
			{:else}
				<div class='lander'>
					<h1 on:click={landedGo}>dive</h1>
					<img on:click={landedGo} src="/images/cover.jpg" alt="Cover" />
				</div>
			{/if}
		</Grid>
	</Content>
</div>
{#if landed == true}
	<div class='menuBox' transition:fade>
		<Content>
			<Grid>
				<Column>
					<Row style='margin-bottom:-0.7rem; cursor:crosshair;'><p on:click={()=>pageChange(0)}>text</p></Row>
					<Row style='margin-bottom:-0.7rem; cursor:crosshair;'><p on:click={()=>pageChange(1)}>image</p></Row>
					<Row style='margin-bottom:-0.7rem; cursor:crosshair;'><p on:click={()=>location.replace('https://clay.dust.supply')}>clay</p></Row>
					<Row style='margin-bottom:-0.7rem; cursor:crosshair;'><p on:click={()=>location.replace('https://ceramic.dust.supply')}>ceramic</p></Row>
					<Row style='margin-bottom:-0.7rem; cursor:crosshair;'><p on:click={()=>location.replace('https://distro.dust.supply/water')}>LISTEN</p></Row>
					<Row style='margin-bottom:-0.7rem; cursor:crosshair;'><p on:click={()=>location.replace('https://dust.supply')}>DUST</p></Row>
				</Column>
			</Grid>
		</Content>
	</div>
	<div class='fixedTitle'>
		<h1 class='transparentTitle'>W A T E R</h1>
	</div>
{:else}
	<GlitchComponent />
{/if}
<div class='clickCounterBox'>
	<Column>
		<Row>clickcount: {clickCount}</Row>
		<Row>lastclickposition<br>{clickPosition}</Row>
		<Row>trackplaying: {trackPlaying}</Row>
		<Row>vocalsample: {vocalSample}</Row>
	</Column>
</div>
<script>
    import {
        Row, 
        Column
    } from 'carbon-components-svelte'

    export let pageLabel
    export let pageIcon

    let nameList = []
    for (let i=0; i<100; i++) {
        nameList.push('|')
    }

</script>

<style>
    .container {
        margin: 0;
        padding: 0;
        width: 70%;
    }

    p { 
        font-size: 2rem;
        color: white;
        font-family: 'Major Mono Display';
    }
    .ilena {
        font-family: 'EB Garamond';
    }
</style>

<svelte:head>
    <title>{pageLabel}</title>
    <link rel='icon' type='image/png' href={pageIcon}>
</svelte:head>

<div class='container'>
    <Row style='margin-bottom: 100%; margin-top:5rem;'>
        <Column>
            <p>Water is the first in a series of three tapes by Comf - an exploration of Textures in relation to Sound.</p>
        </Column>
    </Row>
    <Row style='margin-bottom: 100%; margin-top:5rem;'>
        <Column>
            <p> 
                Sweeping statements, 
                Generalisations, 
                Cast away
                
                Bed of the ocean, 
                Floating, solving, 
                Mind’s decay 
                
                I thought I knew, 
                Better than, 
                To let myself 
                Be tangled in this fray 
                
                A world away, 
                I wanna flow, 
                But can I change,
                
                I’m a river running backwards, 
                And I’ve been throwing stones your way 
                I really wanna call you, 
                And make this thing all okay 
                
                I just wanna move it forward
                It’s important to be straight 
                My minds flowing over 
                With just too many words to say 
                </p>
        </Column>
    </Row>
    <Row style='margin-bottom: 120%;'>
        <Column>
            <p>I can’t think, 
                I can’t sleep, 
                I can’t listen to you speak x2 
                
                Like stones becoming sand 
                I’m destined to rock back 
                And forth 
                Until I'm sore
                My bones are torn 
                My skins all worn 
                You take me home 
                We drive on the coast 
                The hills meet the road 
                Park where it plateaus 
                
                And you told me 
                And you told me 
                You told me 
                You’re just another drop of rain 
                Another drop of rain x3
                
                You don’t know much if you 
                Think you’ll buy me off with 
                All those lies you spent on 
                Drops of rain x6
                </p>
        </Column>
    </Row>
    <Row style='margin-bottom: 120%;'>
        <Column>
            <p>we're all drowning in some way. we can't all be expected to keep afloat, but we try.</p>
        </Column>
    </Row>
    <Row style='margin-bottom: 120%;'>
        <Column>
            <p>I don’t think you’re ready for it, 
                I don’t think you even wanna know, 
                I’ll bet this goes further than you, 
                Really want to show 
                
                Some side of me 
                You just won’t see 
                Where fields are green 
                And space is far between 
                The clearer view 
                When I think of you 
                And nothing else 
                Gonna be us two 
                
                I don’t think you’re ready for it, 
                I don’t think you even wanna know, 
                I’ll bet this goes further than you, 
                Really want to show 
                
                I don’t think you’re telling me,
                All you know 
                I’ve come too far to let this go x4
                </p>
        </Column>
    </Row>
    <Row style='margin-bottom: 120%;'>
        <Column>
            <p>
                I told you this time it won’t be different
                But I don’t wanna say I 
                But it seems we don’t wanna listen 
                But I don’t wanna say I x4
                
                I told you so x8 
                
                It’s a material world 
                But I can’t see through Silk, 
                As I watch you unfurl,
                I can’t help but to think, 
                We’d be better off alone, 
                Just like me and the Beach, 
                As the waves dance and twirl 
                I just watch from the street 
                
                I told you this time it won’t be different
                But I don’t wanna say I 
                But it seems we don’t wanna listen 
                But I don’t wanna say I x4
                
                I told you so x8 
            </p>
        </Column>
    </Row>
    <Row style='margin-bottom: 130%;'>
        <Column>
            <p>i've been treading water for a while now. i'll keep treading as long as i can, but i dont know how much longer</p>
        </Column>
    </Row>
    <Row style='margin-bottom: 130%;'>
        <Column>
            <p>You know how much you mean to me 
                I’m scared I’ll drain you of your energy,
                So many times it’s caught me mentally, 
                I know I’ll get to you eventually 
                
                Damned if I do 
                Blocking the passage 
                Time stretches onwards 
                Clock hands whip lashes 
                
                Oh, and you’ve what you knew 
                When my thoughts stick to me and bounce of you 
                Important dates, that reveal truths 
                And I don’t want, to have to choose 
                
                Alienate me x3 
                Before I’m afraid that you hate me 
                
                Alienate me x3 
                Before I’m afraid that you hate me
            </p>
            <p class="ilena">
                You don’t call, 
                I can’t control how this unfolded, 
                You weren’t being honest 
                Why don’t you call, 
                I couldn’t control, 
                Myself in the moment, 
                When you weren’t being honest, 
                Tried to love the dark, 
                Hit me in the heart, 
                Now these days apart, 
                Hits me in the heart, 
                
                Alienate me, 
                Alienate me, 
                Just Alienate me, 
                Do you hate me, yet? X2
                
            </p>
        </Column>
    </Row>
    <Row style='margin-bottom: 140%;'>
        <Column>
            <p>As the river burns, 
                As the ferns unfurl, 
                Watch them twist and shake,
                Drop down to the earth,
                And as I’ve come to learn, 
                It got harder to discern, 
                Where your emotions lay, 
                And if my feelings were deserved, 
                
                You’re sinking me, 
                Into the cold, 
                I’m at home with two missed calls, 
                I’m not as open as I’d hope, 
                But this switch was just so raw 
                It was just so raw 
                
                
                Test me, 
                Test my patience, 
                And leave me, 
                Leave me waiting,
                But I don’t care, 
                Cause I’m still here x2, 
                But I shouldn’t be, 
                Take me, 
                Take me for granted, 
                And leave me, 
                Leave me empty handed, 
                And I’ll stand there,
                Without a care, 
                You just gotta need
                
            </p>
        </Column>
    </Row>
    <Row style='margin-bottom: 140%;'>
        <Column>
            <p>i'm drowning</p>
        </Column>
    </Row>
</div>
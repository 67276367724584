<script>
    import {
        Row, 
        Column
    } from 'carbon-components-svelte'

    import {
        Draggable
    } from './sick'

    export let pageLabel
    export let pageIcon

    let totalImages = [
        '_ (1).jpeg',
        '_ (1).jpg',
        '_ (1).png',
        '_ (2).jpeg',
        '_ (2).jpg',
        '_ (2).png',
        '_ (3).jpeg',
        '_ (3).jpg',
        '_ (3).png',
        '_ (4).jpg',
        '_ (4).png',
        '_ (5).jpg',
        '_ (6).jpg',
        '_ (7).jpg',
        '_ (8).jpg',
        '_ (9).jpg',
        '_ (10).jpg',
        '_ (11).jpg',
        '_ (12).jpg',
        '_ (13).jpg',
        '_ (14).jpg',
        '_ (15).jpg',
        '_ (16).jpg',
        '_ (17).jpg',
        '_ (18).jpg',
        '_ (19).jpg',
        '_ (20).jpg',
        '_ (21).jpg',
        '_ (22).jpg',
        '_ (23).jpg',
        '_ (24).jpg',
        '_ (25).jpg',
        '_ (26).jpg',
        '_ (27).jpg',
        '_ (28).jpg',
        '_ (29).jpg',
        '_ (30).jpg',
        '_ (31).jpg',
        '_ (32).jpg',
        '_ (33).jpg',
        '_ (34).jpg',
        '_ (35).jpg',
        '_ (36).jpg',
        '_ (37).jpg',
        '_ (38).jpg',
        '_ (39).jpg',
        '_ (40).jpg',
        '_ (41).jpg',
        '_ (42).jpg',
        '_ (43).jpg',
        '_ (44).jpg',
        '_ (45).jpg',
        '_ (46).jpg',
        '_ (47).jpg',
        '_ (48).jpg',
        '_ (49).jpg',
        '_ (50).jpg',
    ]

</script>

<style>
    img {
        width: 100%;
    }
</style>

<svelte:head>
    <title>{pageLabel}</title>
    <link rel='icon' type='image/png' href={pageIcon}>
</svelte:head>

<Row style='margin-top: 5rem;'>
    <Column>
        {#each totalImages as picSource}
            <Draggable>
                <img src="images/{picSource}" alt='piccy'>
            </Draggable>
        {/each}
    </Column>
</Row>